import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { apiGetProducts } from '../../../../api/apiProducts';
import ProductCarousel from '../../carousels/ProductCarousel';
import { Grid, Container, Box } from '@mui/material';
import LoadingScreen from '../../loading/LoadingScreen';
import ImageSection from './components/ImageSection';
import ProductSection from './components/ProductSection';
import ProductDetails from './components/ProductDetails';
import MissingPage from '../../errorMessages/MissingPage';
import Standards from '../../../../assets/products_svg.svg';
import { apiGetYouMayAlsoLikeData } from '../../../../api/apiCollections';
import FilterComponent from '../../filter/FilterComponent';
import { captureAnalytics } from '../../../../utilities/Analytics';

function ProductPage() {
    let { item_group, application, collection, design, colour }: any =
        useParams();
    const location = useLocation();

    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [currentSelectedItem, setCurrentSelectedItem] = useState<any>();
    const [selectedImagePreviewIndex, setSelectedImagePreviewIndex] =
        useState<number>(0);

    const {
        isSuccess,
        isError,
        isLoading,
        data: productData,
    } = useQuery<any, Error>(
        [
            `product-${design}`,
            item_group,
            application,
            collection,
            design,
            colour,
        ],
        () => apiGetProducts(item_group, application, collection, design),
        {
            onSuccess: (data) => {
                if (colour !== undefined) {
                    const initialColourIndex = data.findIndex(
                        (data: any) => data?.StockMaster?.colour === colour
                    );
                    setCurrentIndex(initialColourIndex);
                    setSelectedImagePreviewIndex(initialColourIndex);
                }
            },
        }
    );

    const { isSuccess: youMayAlsoLikeSuccess, data: youMayAlsoLikeData } =
        useQuery<any, Error>(
            [`you-may-also-like-${design}`],
            () =>
                apiGetYouMayAlsoLikeData(
                    parseInt(productData[currentIndex]?.StockMaster?.stock_link)
                ),
            {
                //! This ensures the query runs only if productData is defined and not empty
                enabled:
                    !!productData &&
                    productData.length > 0 &&
                    productData[currentIndex]?.StockMaster?.stock_link !==
                        undefined,
                staleTime: 900000,
            }
        );

    const productDetailsList =
        productData && productData.length > 0
            ? [
                  {
                      key: 'Product Code (SKU)',
                      value: productData[currentIndex]?.Product?.sku,
                  },
                  {
                      key: 'Description',
                      value: productData[currentIndex]?.StockMaster
                          ?.description_1,
                  },
                  {
                      key: 'Design',
                      value: productData[currentIndex]?.StockMaster?.design,
                  },
                  {
                      key: 'Colour',
                      value: productData[currentIndex]?.StockMaster?.colour,
                  },
                  {
                      key: 'Range',
                      value: productData[currentIndex]?.StockMaster?.range,
                  },

                  {
                      key: 'Brand',
                      value: productData[currentIndex]?.StockMaster?.brand,
                  },
                  ...(productData[currentIndex]?.StockMaster?.group_desc ===
                  'Rugs'
                      ? [
                            {
                                key: 'Size',
                                value: `${productData[currentIndex]?.StockMaster?.width} x ${productData[currentIndex]?.StockMaster?.length_cm}`,
                            },
                        ]
                      : []),
                  ...(productData[currentIndex]?.StockMaster?.brand === 'HAUS'
                      ? [
                            {
                                key: 'Weight',
                                value: productData[currentIndex]?.StockMaster
                                    ?.c_weight,
                            },
                        ]
                      : []),
                  ...(productData[currentIndex]?.StockMaster?.group_desc ===
                  'Fabric'
                      ? [
                            {
                                key: 'Width',
                                value: productData[currentIndex]?.StockMaster
                                    ?.width,
                            },
                        ]
                      : []),
                  ...(productData[currentIndex]?.StockMaster?.group_desc ===
                  'Wallpaper'
                      ? [
                            {
                                key: 'Roll Dimension',
                                value: `${productData[currentIndex]?.StockMaster?.width} x ${productData[currentIndex]?.StockMaster?.length_cm}`,
                            },
                        ]
                      : []),

                  ...(productData[currentIndex]?.StockMaster?.group_desc ===
                      'Fabric' ||
                  productData[currentIndex]?.StockMaster?.group_desc ===
                      'Wallpaper'
                      ? [
                            {
                                key: 'Vertical Repeat',
                                value: productData[currentIndex]?.StockMaster
                                    ?.repeat_t,
                            },
                        ]
                      : []),

                  ...(productData[currentIndex]?.StockMaster?.group_desc ===
                      'Wallpaper' ||
                  productData[currentIndex]?.StockMaster?.group_desc === 'Rugs'
                      ? [
                            {
                                key: 'Composition',
                                value: productData[currentIndex]?.StockMaster
                                    ?.composition,
                            },
                        ]
                      : []),
                  ...(productData[currentIndex]?.StockMaster?.group_desc ===
                  'Wallpaper'
                      ? [
                            {
                                key: 'Installation',
                                value: productData[currentIndex]?.StockMaster
                                    ?.hanging_instructions,
                            },
                        ]
                      : []),
                  ...(productData[currentIndex]?.StockMaster?.group_desc ===
                  'Fabric'
                      ? [
                            {
                                key: 'Horizontal Repeat',
                                value: productData[currentIndex]?.StockMaster
                                    ?.repeat_h,
                            },
                        ]
                      : []),
                  ...(productData[currentIndex]?.StockMaster?.group_desc ===
                  'Fabric'
                      ? [
                            {
                                key: 'Railroaded',
                                value: productData[currentIndex]?.StockMaster
                                    ?.railroaded,
                            },
                        ]
                      : []),
                  ...(productData[currentIndex]?.StockMaster?.group_desc ===
                  'Fabric'
                      ? [
                            {
                                key: 'Composition',
                                value: productData[currentIndex]?.StockMaster
                                    ?.composition,
                            },
                        ]
                      : []),
                  ...(productData[currentIndex]?.StockMaster?.group_desc ===
                  'Fabric'
                      ? [
                            {
                                key: 'Weight',
                                value: productData[currentIndex]?.StockMaster
                                    ?.c_weight,
                            },
                        ]
                      : []),

                  {
                      key: 'Care Info',
                      value: Array.isArray(
                          productData[currentIndex]?.Product
                              ?.cleaning_instructions
                      ) ? (
                          <Box
                              sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  gap: '7px',
                              }}
                          >
                              {productData[
                                  currentIndex
                              ]?.Product?.cleaning_instructions.map(
                                  (instruction: any, index: any) => (
                                      <img
                                          key={index}
                                          src={instruction.image_data}
                                          alt={instruction.file_name}
                                          style={{
                                              width: '30px',
                                              height: '30px',
                                              paddingRight: '0',
                                          }}
                                      />
                                  )
                              )}
                          </Box>
                      ) : null,
                  },
                  ...(productData[currentIndex]?.StockMaster?.group_desc ===
                  'Fabric'
                      ? [
                            {
                                key: 'Martindale Rubs',
                                value: productData[currentIndex]?.StockMaster
                                    ?.martindale,
                            },
                        ]
                      : []),
                  ...(productData[currentIndex]?.StockMaster?.group_desc ===
                  'Fabric'
                      ? [
                            {
                                key: 'Recommended Use',
                                value: productData[currentIndex]?.StockMaster
                                    ?.application,
                            },
                        ]
                      : []),

                  {
                      key: 'Comments',
                      value: productData[currentIndex]?.Product
                          ?.disambiguating_description,
                  },
                  {
                      key: 'Standards',
                      value: Array.isArray(
                          productData[currentIndex]?.Product?.standards
                      ) ? (
                          <Box
                              sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  gap: '10px',
                              }}
                          >
                              {productData[
                                  currentIndex
                              ]?.Product?.standards.map(
                                  (standard: any, index: any) => (
                                      <img
                                          key={index}
                                          src={standard.image_data}
                                          alt={standard.file_name}
                                          style={{
                                              height: '60px',
                                              paddingRight: '0',
                                          }}
                                      />
                                  )
                              )}
                          </Box>
                      ) : null,
                  },
                  //   ! Client may revert
                  //   {
                  //       key: 'Type',
                  //       value: Array.isArray(
                  //           productData[currentIndex]?.Product?.symbols
                  //       ) ? (
                  //           <Box
                  //               sx={{
                  //                   display: 'flex',
                  //                   flexDirection: 'row',
                  //                   gap: '10px',
                  //               }}
                  //           >
                  //               {productData[currentIndex]?.Product?.symbols.map(
                  //                   (symbol: any, index: any) => (
                  //                       <img
                  //                           key={index}
                  //                           src={symbol.image_data}
                  //                           alt={symbol.file_name}
                  //                           style={{
                  //                               width: '60px',
                  //                               height: '60px',
                  //                               paddingRight: '0',
                  //                           }}
                  //                       />
                  //                   )
                  //               )}
                  //           </Box>
                  //       ) : null,
                  //   },
              ]
            : [];
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [colour]);

    useEffect(() => {
        captureAnalytics('pageview', location.pathname, `product-${design}`);
    }, [location.pathname, design]);

    return (
        <>
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <>
                    {isError && <MissingPage />}
                    {isSuccess && productData && (
                        <>
                            <Container maxWidth='xl'>
                                <Grid
                                    mt={{ xs: 5, sm: 10 }}
                                    container
                                    sx={{
                                        flexDirection: {
                                            xs: 'column',
                                            lg: 'row',
                                        },
                                    }}
                                >
                                    <ImageSection
                                        productListData={productData}
                                        currentIndex={currentIndex}
                                        setCurrentIndex={setCurrentIndex}
                                        currentSelectedItem={
                                            currentSelectedItem
                                        }
                                        selectedImagePreviewIndex={
                                            selectedImagePreviewIndex
                                        }
                                    />

                                    <ProductSection
                                        productListData={productData}
                                        productData={productData}
                                        currentIndex={currentIndex}
                                        setCurrentIndex={setCurrentIndex}
                                        itemGroup={item_group}
                                        application={application}
                                        collection={collection}
                                        design={design}
                                        colour={colour}
                                        currentSelectedItem={
                                            currentSelectedItem
                                        }
                                        setCurrentSelectedItem={
                                            setCurrentSelectedItem
                                        }
                                        setSelectedImagePreviewIndex={
                                            setSelectedImagePreviewIndex
                                        }
                                        selectedImagePreviewIndex={
                                            selectedImagePreviewIndex
                                        }
                                    />
                                </Grid>

                                <ProductDetails
                                    productDetailsList={productDetailsList}
                                />

                                {/* Client request to hide - relook at a later stage */}

                                {/* {youMayAlsoLikeSuccess && (
                                    <ProductCarousel
                                        header='You may also like'
                                        marginTop={10}
                                        marginBottom={10}
                                        products={youMayAlsoLikeData}
                                    />
                                )} */}

                                <FilterComponent
                                    itemGroup={item_group}
                                    title={application}
                                />
                            </Container>
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default ProductPage;
